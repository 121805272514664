import React, { useState, useEffect } from "react"
import { Link, navigate, graphql } from "gatsby"

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import SubscriptionForm from "../components/common/subscribe"
import ShareButtons from "../components/common/sharebuttons"
import Adsense from "../components/promotions/adsense"
//import Image from "../components/common/image"
import {
  RedditIcon,
} from 'react-share'

import withLocation from "../components/common/withlocation"
import CommunitiesTable from "../components/community/communitiestable"
import { trackEvent } from "../utils/tracking"
import { getParamsFromUrl } from "../utils/urls"
import { unslugify } from '../utils/text'

import { ChevronRightIcon } from '@heroicons/react/solid'
import { ClipboardListIcon, UserGroupIcon, PlusCircleIcon, DocumentAddIcon, InformationCircleIcon, BackspaceIcon } from '@heroicons/react/outline'


const RedirectPage = ({ location, data }) => {
  // state
  const { url, style, topics, platform, name, slug, status, redirected } = getParamsFromUrl(location);
  const communityName = decodeURIComponent(name);  // in case it has special characters
  const [showMoreOptions, setShowMoreOptions] = useState(redirected ? true : false);
  const [showSubscribeForm, setShowSubscribeForm] = useState(true);
  const promotedSimilarCommunities = data.sponsoredCommunities.nodes.filter(c => {
    return (c.slug !== slug) && topics && (topics.split(',').filter(value => c.topics.split(',').includes(value)).length > 0);
  });
  const sponsor = data.sponsors.nodes[0];


  // ratios of redirect screens we're showing
  var redirectStyles = [
    {'style': 'Subscribe', 'weight': 3},
    {'style': 'Twitter', 'weight': 1},
    {'style': 'Subreddit', 'weight': 1},
    {'style': 'Share', 'weight': 5},

    // {'style': 'Adsense', 'weight': 1},
    // {'style': 'Survey', 'weight': 0},
  ]
  if (sponsor){
    redirectStyles.push({'style': 'Sponsor', 'weight': 10}); // add to the mix if we have a sponsor
  }

  // make a random pool based off of these options, and pick one (unless there's a query param for forcing the style)
  var redirectPool = []; // make a bit list of all the options
  redirectStyles.forEach(r => redirectPool = [...redirectPool, ...Array(r.weight).fill(r.style)]);
  const [redirectStyle, setRedirectStyle] = useState(redirectPool[parseInt(Math.random() * redirectPool.length)]);
  useEffect(() => {
    if (style) setRedirectStyle(style);
  }, [style]); // if we overwrite from the query params, set that to be the redirect style

  // set the url to redirect to into the state
  const [urlForRedirect, setUrlForRedirect] = useState(null);
  useEffect(() => {
    if (url){
      var redirectUrl = decodeURIComponent(url);

      // append query tracking params (if there are no hashes in the URL)
      if (!redirectUrl.includes('#')){
        if (redirectUrl.includes('?')){
          redirectUrl += '&ref=thehiveindex.com&utm_source=thehiveindex.com'
        } else {
          redirectUrl += '?ref=thehiveindex.com&utm_source=thehiveindex.com'
        }
        if (status === 'sponsored'){
          redirectUrl += '&utm_campaign=featured'
        }
      }
  
      setUrlForRedirect(redirectUrl)
    }
  }, [url]); // if we overwrite from the query params, recalculate

  // actions
  const onRedirect = () => {
    setShowMoreOptions(true);  // change this page
    if (location){
       // set the query param in case they go back
      navigate(location.pathname + location.search + '&redirected=1')
    }

    // track event
    trackEvent('Redirected', {
      'event_label': communityName,
      // 'type': 'community',
      'name': communityName,
      'slug': slug,
      'platform': platform,
      'topics': topics,
      'redirect_style': redirectStyle,
      'status': status,
    })
  }

  // render utils
  const moreOptions = [
    ...(topics || '').split(',').map(t => {
      return { title: `View ${unslugify(t)} topic`, link: `/topics/${t}/`, description: `Explore all ${unslugify(t)} communities`, icon: PlusCircleIcon }
    }),
    { title: `Go back to ${communityName}`, link: `/communities/${slug}/`, description: `View similar communities & reviews`, icon: BackspaceIcon },
    // { title: 'Suggest', link: '/suggest/', description: 'Submit a new community or topic', icon: DocumentAddIcon },
    { title: 'Topics', link: '/topics/', description: 'Browse all community topics', icon: ClipboardListIcon },
    { title: 'About', link: '/', description: 'Learn more about this site', icon: InformationCircleIcon },
  ]

  return (

    <Layout pageTitle="">
      <SEO title={`Redirect | ${showMoreOptions ? 'More' : redirectStyle}`} dontIndex={true} />

      <div className="max-w-5xl mx-auto">
        {showMoreOptions ? (
          <div className="text-center max-w-5xl mx-auto">
            <p className="text-sm font-semibold text-rose-600 uppercase tracking-wide">The Hive Index</p>
            
            {promotedSimilarCommunities.length ? (
              <div className="">
                <h2 className="mt-4 text-2xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  You might also like
                </h2>
                <div className="p-4 sm:p-6">
                  <CommunitiesTable communities={promotedSimilarCommunities} showPlatform={true} showTopics={true} linkModal={false} />
                </div>
              </div>
            ) : ''}


            <h1 className="mt-4 text-2xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              Keep exploring communities
            </h1>
            <div className="text-left mt-8 max-w-xl mx-auto">
              <ul className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                {moreOptions.map((link, linkIdx) => (
                  <li key={linkIdx} className="relative py-6 flex items-start space-x-4">
                    <div className="flex-shrink-0">
                      <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-rose-50">
                        <link.icon className="h-6 w-6 text-rose-700" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1">
                      <h3 className="text-base font-medium text-gray-900">
                        <span className="rounded-sm">
                          <Link to={link.link} className="focus:outline-none">
                            <span className="absolute inset-0" aria-hidden="true" />
                            {link.title}
                          </Link>
                        </span>
                      </h3>
                      <p className="text-base text-gray-500">{link.description}</p>
                    </div>
                    <div className="flex-shrink-0 self-center">
                      <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <div className="text-center max-w-5xl mx-auto">
            <p className="text-sm font-semibold text-rose-600 uppercase tracking-wide">Leaving The Hive Index</p>

            {redirectStyle === "Transition" ? (
              <div className="max-w-5xl mx-auto">
                <h1 className="mt-4 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  <span role="img">👋</span> Thanks for visiting!
                </h1>
                <p className="mt-4 text-lg text-gray-500">
                  Click the button below to visit this community in a new tab.
                </p>
                <p className="mt-2 text-lg text-gray-500">
                  Come back here to see more options on the Hive Index.
                </p>
              </div>
            ) : redirectStyle === "Subscribe" ? (
              <React.Fragment>
                <h1 className="mt-4 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  <span role="img">👋</span> Before you go...
                </h1>
                <p className="mt-4 text-lg text-gray-500">
                  Would you like to stay in the loop of new communities on this site?
                </p>
                
                {showSubscribeForm ? (
                  <div className="mt-4 text-lg mx-auto block">
                    <SubscriptionForm topics={topics} community={communityName} platform={platform} />
                  </div>
                ) : (
                  <div className="mt-4 text-lg mx-auto block">
                    <button className="cursor-pointer w-96 mx-auto items-center mt-4 px-8 py-4 font-semibold text-white bg-gray-200 hover:bg-gray-300 shadow-sm rounded-sm text-gray-700"
                      onClick={() => setShowSubscribeForm(true)}>
                      I'm intrigued, tell me more
                    </button>
                  </div>
                )}
              </React.Fragment>
            ) : redirectStyle === "Share" ? (
              <React.Fragment>
                <h1 className="mt-4 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  <span role="img">👋</span> Before you go...
                </h1>
                <p className="mt-4 text-lg text-gray-500">
                  This free resource gets better when more people know about it!
                </p>
                <p className="mt-2 text-lg text-gray-500">
                  Have any friends that you would like to share it with?
                </p>
                
                <div className="flex mt-4 mb-8">
                  <div className="mx-auto text-center">
                    <ShareButtons title={"I'm finding amazing communities on the Hive Index"} openShareButtons={true}
                      urlOverride={'https://thehiveindex.com'}
                    />
                  </div>
                </div>
              </React.Fragment>
            ) : redirectStyle === "Twitter" ? (
              <React.Fragment>
                <h1 className="mt-4 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  <span role="img">👋</span> Before you go...
                </h1>
                <p className="mt-4 text-lg text-gray-500">
                  Are you on Twitter? Give us a follow!
                </p>
                <p className="mt-2 text-lg text-gray-500">
                  Stay in touch with the world of online communities
                </p>
                <div className="flex mt-4 mb-8">
                  <div className="mx-auto">
                    <a href="https://twitter.com/thehiveindex?ref_src=twsrc%5Etfw" className="twitter-follow-button p-2 px-3 rounded-full" target="_blank"
                      style={{'backgroundColor': '#1DA1F2', 'color': 'white'}}
                      data-size="large" data-show-count="false" data-dnt="true"
                      onClick={() => {
                        trackEvent('TwitterFollow', {
                          'account': 'thehiveindex',
                        })
                      }}
                    >
                      <span>Follow @thehiveindex</span>
                    </a>
                  </div>
                </div>
              </React.Fragment>
            ) : redirectStyle === "Twitter Maker" ? (
              <React.Fragment>
                <h1 className="mt-4 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  <span role="img">👋</span> Before you go...
                </h1>
                <p className="mt-4 text-lg text-gray-500">
                  Want to be friends on Twitter?
                </p>
                <p className="mt-2 text-lg text-gray-500">
                  Follow the indie maker of the Hive Index (and other community-first products)
                </p>
                <div className="flex mt-4 mb-8">
                  <div className="mx-auto">
                    <a href="https://twitter.com/foliofed?ref_src=twsrc%5Etfw" className="twitter-follow-button p-2 px-3 rounded-full" target="_blank"
                      style={{'backgroundColor': '#1DA1F2', 'color': 'white'}}
                      data-size="large" data-show-count="false" data-dnt="true"
                      onClick={() => {
                        trackEvent('TwitterFollow', {
                          'account': 'foliofed',
                        })
                      }}
                    >
                      <span>Follow @foliofed</span>
                    </a>
                  </div>
                </div>
              </React.Fragment>
            ) : redirectStyle === "Subreddit" ? (
              <React.Fragment>
                <h1 className="mt-4 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  <span role="img">👋</span> Before you go...
                </h1>
                <p className="mt-4 text-lg text-gray-500">
                  Want to stay in touch with newly launched communities?
                </p>
                <p className="mt-2 text-lg text-gray-500">
                  Join the r/hiveindex subreddit!
                </p>
                <div className="flex mt-4 mb-8">
                  <div className="mx-auto">
                    <a href="https://www.reddit.com/r/hiveindex/" className="p-2 px-3 rounded-full flex items-center" target="_blank"
                      style={{'backgroundColor': '#FF4500', 'color': 'white'}}
                      // data-size="large" data-show-count="false" data-dnt="true"
                      onClick={() => {
                        trackEvent('SubredditView', {
                          'account': 'r/hiveindex',
                        })
                      }}
                    >
                      <RedditIcon size={20} round={true} color={'white'} />
                      <span className="ml-2 font-bold">r/hiveindex</span>
                    </a>
                  </div>
                </div>
              </React.Fragment>
            ) : redirectStyle === "Adsense" ? (
              <React.Fragment>
                <h1 className="mt-4 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  <span role="img">👋</span> Thanks for visiting
                </h1>
                <div className="mt-4 mb-8 block p-4 mx-auto">
                  <Adsense id="8367367921" type="redirect" />
                </div>
              </React.Fragment>
            ) : redirectStyle === "Sponsor" ? (
              <React.Fragment>
                <h1 className="mt-4 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  <span role="img">👋</span> Before you go...
                </h1>
                <p className="mt-4 text-lg text-gray-500">
                  This site is sponsored this week by:
                  <br/><b>{sponsor.name} ({sponsor.descriptor})</b>
                  <br/>If you have a minute before you go, check them out!
                </p>
                <div className="flex mt-8 mb-8">
                  <div className="mx-auto">
                    <a href={`${sponsor.url}${sponsor.url.includes('?') ? '&' : '?'}ref=thehiveindex.com&utm_source=thehiveindex.com&utm_campaign=sponsor`}
                      className="cursor-pointer w-96 mx-auto items-center mt-4 px-8 py-4 font-semibold text-white bg-gray-200 hover:bg-gray-300 shadow-sm rounded-sm text-gray-700" target="_blank"
                      onClick={() => {
                        trackEvent("SponsorLinkClick", 
                          Object.assign({
                            'location': 'redirect',
                          }, sponsor) // add all sponsor info, and also the location
                        );
                      }}
                    >
                      {sponsor.cta} →
                    </a>
                  </div>
                </div>
              </React.Fragment>
            ) : ''}

            <div className="block max-w-xl mx-auto mt-4 text-lg text-gray-500 text-md">
              {urlForRedirect ? (
                <a href={urlForRedirect} target="_blank" rel="noopener" onClick={onRedirect}
                  id="redirect-button"
                  className="cursor-pointer w-96 mx-auto flex justify-center items-center px-12 py-2 border border-rose-500 text-white bg-rose-500 hover:bg-rose-600 shadow-sm rounded-sm text-white"
                >
                  <div className="mr-4">
                    <div className="opacity-100 font-medium max-w-xs truncate">Go to the community</div>
                    <div className="opacity-75 text-sm font-semibold">{communityName}</div>
                  </div>
                  <div>→</div>
                </a>
              ) : ''}
            </div>
          </div>
        )}
      </div>

    </Layout>
  )
};

export const query = graphql`
  query{
    sponsoredCommunities: allGoogleSpreadsheetSourceCommunities(limit: 12, filter: {sponsored: {eq: true}, removed: {ne: true}}){
      totalCount
      nodes {
        name
        slug
        updated(formatString: "MM/DD/YY")
        members
        established
        description
        topics
        platform
        logo
        forum
        chat
        apply
        pairing
        events
        perks
        jobs
        newsletter
        paid
        courses
        tools
        token
        reviewCount
        reviewAvg
      }
    }
    sponsors: allGoogleSpreadsheetSourcePromotedSponsors(sort: { order: ASC, fields: [order] }, filter: {active: {eq: "TRUE"}}) {
      nodes {
        key
        active
        name
        url
        descriptor
        cta
      }
    }
  }
`

export default withLocation(RedirectPage);
